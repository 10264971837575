import React from 'react';
import './App.css';
import { Route, Routes } from 'react-router-dom';
import BackgroundParticle from './components/BackgroundParticle';
import MainPage from './page/MainPage';

function App() {
  return (
    <Routes>
        <Route path="/" element={<div>
          <BackgroundParticle />
          <MainPage />
        </div>} />
    </Routes>
  );
}

export default App;

import "../asset/css/particle.css";

const BackgroundParticle = () => {
    return (
        <div className="background">
            {
                Array.from({ length: 100 }).map((_, index) => (
                    <span key={index}></span>
                ))
            }
        </div>
    )
}

export default BackgroundParticle;
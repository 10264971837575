export interface Achievement{
    title: string;
    description: string[];
    level : string;
    id: number;
    imagePath : string;
}

export const  achievementData : Achievement[] = [
    {
        title: 'Bronze Medalist ICPC Asia Jakarta 2022',
        description: ['International Collegiate Programming Contest (ICPC) is an annual multi-tiered competitive programming competition among universities in the world',' The Asia Jakarta Regional Contest is held to select delegates from its region to participate in the World Finals'],
        level : 'Asia Pacific',
        id: 1,
        imagePath : '/icpc.png'
    },
    {
        title : 'Top 2% in IEEEXtreme Programming Competition 16.0',
        description : ['IEEEXtreme is a global challenge in which teams of IEEE Student members compete in a 24-hour time span against each other to solve a set of programming problems'],
        level : 'International',
        id : 2,
        imagePath : '/ieee.png'
    },
    {
        title : '5th Codeplay 2023',
        description : ['Codeplay is a competitive programming competition held by Binus University'],
        level : 'National',
        id : 3,
        imagePath : '/binus.png'
    },
    {
        title : 'Top 10 Indonesia National Competition (INC) 2023',
        description : ['INC is a national competitive programming competition as a regional selection for ICPC Asia Jakarta Regional Contest'],
        level : 'National',
        id : 4,
        imagePath : '/inc.jpeg'
    },
    {
        title : 'Top 10 GEMASTIK 2022 - Programming Category',
        description : ['GEMASTIK is an annual IT competition event held by The Ministry of Research and Technology of Indonesia. It has multiple competitions, one of them is competitive programming competition'],
        level : 'National',
        id : 5,
        imagePath : '/gemastik.png'
    },
    {
        title : 'Top 10 in SCPC Compfest 2023',
        description : ['SCPC Compfest is an annual competitive programming competition for college students in Indonesia held by University of Indonesia'],
        level : 'National',
        id : 6,
        imagePath : '/compfest.png'
    },
    {
        title : 'Top 10 in Schematics Competitive Programming',
        description : ['Schematics is an annual competitive programming competition for college students in Indonesia held by Surabaya Institute of Technology (ITS)'],
        level : 'National',
        id : 7,
        imagePath : '/schematics.jpg'
    }
]
import { Button, Card, Col, Modal, Row, Space } from "antd";
import { useState } from "react";
import ReactVisibilitySensor from "react-visibility-sensor";
import { Project } from "../data/ProjectData";
import Meta from "antd/es/card/Meta";
import { GithubOutlined, GlobalOutlined } from "@ant-design/icons";


const ListProjects = ({data} : {data:Project[]}) => {
    const [isVisible, setIsVisible] = useState(data.map((elmt : Project,idx : number) => false));
    const [modalOpen, setModalOpen] = useState(false);
    const [choosenProject, setChoosenProject] = useState(1);
    return (
        <div>
        <Row gutter={[16, 24]}justify={"center"}>
                    {data.map((item) => (
                        <Col key={item.id} className="gutter-row" sm={24} md={12} lg={8} >
                                <ReactVisibilitySensor
                                    partialVisibility={true}
                                    onChange={(isInViewport : boolean) => {
                                        isVisible[item.id-1] = isInViewport;
                                        setIsVisible([...isVisible]);
                                }}>
                                     <Card
                                     className="hoverable-card"
                                     onClick={function(){
                                            setChoosenProject(item.id);
                                            setModalOpen(true);
                                     }}
                                        style={{ maxWidth: 250,backgroundColor:'#fbf3d5',height:'100%',opacity: isVisible[item.id-1] ? 1 : 0.25,transition: 'opacity 500ms linear' }}
                                        cover={
                                        <img
                                            alt="project-img"
                                            src={item.imagePath}
                                            style={{ width:'100%', height:'100%',aspectRatio: '1/1',padding:'15px'}}
                                        />
                                        }
                                    >
                                        <Meta
                                        style={{ backgroundColor:'#D6DAC8',borderRadius:'5px',textAlign:'center'}}
                                        title={<p style={{ whiteSpace:'normal' }}>{item.title}</p>}
                                        />
                                    </Card>
                                </ReactVisibilitySensor>
                            </Col>
                    ))}
                    </Row>
                    <Modal
                        title={<p style={{ textAlign:'center' }}>{data[choosenProject-1].title}</p>}
                        centered
                        open={modalOpen}
                        onOk={() => setModalOpen(false)}
                        onCancel={() => setModalOpen(false)}
                        cancelButtonProps={{ style: { display: 'none' } }}
                        okButtonProps={{ style: { display: 'none' } }}
                        style={{ maxWidth:'350px',backgroundColor:'#fbf3d5',borderRadius:'5px' }}
                        styles={{ content:{backgroundColor:'#fbf3d5'},body:{backgroundColor:'#fbf3d5'} ,header:{backgroundColor:'#fbf3d5'} }}
                    >
                        <img src={data[choosenProject-1].imagePath} alt="modal" style={{ width:'90%',height:'auto',marginLeft:'auto',marginRight:'auto',display:'block' }}/>
                        <br/>
                        <Space style={{ display:'flex',justifyContent:'center' }}>
                            {data[choosenProject-1].linkGithub !== '' ? <Button type="text" onClick={() => window.open(data[choosenProject-1].linkGithub)}><GithubOutlined style={{fontSize:25}}/></Button> : <></>}
                            {data[choosenProject-1].linkProject !== '' ? <Button type="text" onClick={() => window.open(data[choosenProject-1].linkProject)}><GlobalOutlined style={{fontSize:25}}/></Button> : <></>}
                        </Space>
                        <p>{data[choosenProject-1].description}</p>
                        <Space style={{ wordWrap:'break-word',display:'flex',flexWrap:'wrap',justifyContent:'center' }}>
                            {data[choosenProject-1].techStacks.map((tech,idx) => (
                                <span key={idx} style={{ backgroundColor:'#D6DAC8',borderRadius:'5px',padding:'5px' }}>{tech}</span>
                            ))}
                        </Space>
                    </Modal>
        </div>
    );
}

export default ListProjects;
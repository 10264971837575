import { GithubOutlined, InstagramOutlined, LinkedinOutlined, MailOutlined } from "@ant-design/icons";
import { Avatar, Button, Col, Image, Row, Space, Tabs } from "antd";
import Typewriter from "../components/TypeWriter";
import ListExperiences from "../components/ListExperiences";
import { Experience,experienceData } from "../data/ExperienceData";
import { Achievement, achievementData } from "../data/AchievementData";
import ListAchievements from "../components/ListAchievements";
import ListProjects from "../components/ListProjects";
import { Project, projectData } from "../data/ProjectData";


const MainPage = () => {

    const experiences : Experience[] = experienceData;
    const achievements : Achievement[] = achievementData;
    const projects : Project[] = projectData;

    const items = [
        {
            label: <b>Experiences</b>,
            key: '1',
            children: <ListExperiences data={experiences}/>,
        },
        {
            label: <b>Achievements</b>,
            key: '2',
            children: <ListAchievements data={achievements}/>,
        },
        {
            label: <b>Projects</b>,
            key: '3',
            children: <ListProjects data={projects}/>,
        }
    ]

    return (
        <div>
            <div className="w3-animate-opacity w3-animate-top" style={{ maxWidth:'fit-content',marginLeft:'auto',marginRight:'auto',marginTop:'5vw',display:'block' }}>
                <Row justify={"center"} align={"middle"}>
                    <Col md={7} span={10}>
                        <h2><Typewriter text="Hi, My Name Is Prima" delay={150}/></h2>
                        <h3>Software Engineer</h3>
                        <p style={{  textAlign:"justify",textJustify:"inter-word" }}>I'm a final year student at Computer Science on Bandung Institute of Technology.</p>
                        <p style={{  textAlign:"justify",textJustify:"inter-word" }}>Mainly interested in software engineering fields especially backend development. Previously, working as a software engineer intern at OCBC NISP and Pragma Informatika.</p>
                        <p style={{  textAlign:"justify",textJustify:"inter-word" }}>I'm also a Competitive Programming enthusiast and have won several awards.</p>
                    </Col>
                    <Col md={1} span={1}/>
                    <Col md={2} span={5}>
                        <Avatar size={150} src="/profile-photo.jpeg" alt="profile-photo" />
                    </Col>
                </Row>
                <Row justify={"center"} align={"middle"}>
                    <Space>
                        <Button type="text" icon={<GithubOutlined style={{fontSize:25}}/>} href="//github.com/primahafiz"/>
                        <Button type="text" icon={<LinkedinOutlined style={{fontSize:25}} />} href="//linkedin.com/in/primahafiz/"/>
                        <Button type="text" icon={<MailOutlined style={{fontSize:25}} />} href="mailto:primahafiz@gmail.com"/>
                        <Button type="text" icon={<Image width={25} src="/codeforces.webp" alt="codeforces" preview={false}/>} href="//codeforces.com/profile/Primesz"/>
                        <Button type="text" icon={<InstagramOutlined style={{fontSize:25}} />} href="//instagram.com/primahafiz28"/>
                    </Space>
                </Row>
                <br/>
                <br/>
            </div>
            <div>
                <Row justify={"center"} align={"middle"}>
                    <Tabs style={{ width:'60vw',minWidth:'300px' }} items={items} centered={true} />
                </Row>
            </div>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
        </div>
    );
}

export default MainPage;
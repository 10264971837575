export interface Project{
    title: string;
    description: string;
    techStacks : string[];
    linkGithub : string;
    linkProject : string;
    id: number;
    imagePath : string;
}

export const  projectData : Project[] = [
    {
        title: 'Personal Website',
        description: 'My personal website that includes my resume and projects.',
        techStacks : ['React.js','Antd'],
        linkGithub : 'https://github.com/primahafiz/personal-web',
        linkProject : 'https://primahafiz.tech',
        id: 1,
        imagePath : '/porto.jpg'
    },
    {
        title: 'Raft Consensus Protocol Implementation',
        description: 'Raft Consensus Protocol is a consensus algorithm used in distributed system in order to achieve agreement on a single data value among distributed processes or systems.',
        techStacks : ['Python','FastAPI','Multithreading'],
        linkGithub : 'https://github.com/primahafiz/raft-distributed-system',
        linkProject : '',
        id: 2,
        imagePath : '/raft.png'
    },
    {
        title: '3D Articulated Model',
        description: 'Web based articulated model visualization using WebGL library. Articulated model consists of multiple primitive 3D shapes that are connected with each other. Develop articulated model rotation and translation transformation using matrix transformation. Develop articulated model shading based on the trajectory of light ray.',
        techStacks : ['Javascript','WebGL'],
        linkGithub : 'https://github.com/primahafiz/IF3260_Tugas3_K01_G01',
        linkProject : 'https://primahafiz.github.io/IF3260_Tugas3_K01_G01/src/index.html',
        id: 3,
        imagePath : '/3dmodel.jpg'
    },
    {
        title: 'Binotify Website',
        description: 'Binotify is a website for managing music data. It consists of 3 main services : PHP service for user, NodeJS services for admin, SOAP service for user subscription.',
        techStacks : ['Docker','ReactJS','ExpressJS','PHP','Java','MySQL'],
        linkGithub : 'https://github.com/primahafiz/binotify',
        linkProject : '',
        id: 4,
        imagePath : '/binotify.jpg'
    },
    {
        title: 'Football Livescore Application',
        description: "Football Live Score Aplication is an android application that provides recent match histories and real time match's livescore of Indonesia National Football Team.",
        techStacks : ['Kotlin','ExpressJS'],
        linkGithub : 'https://github.com/primahafiz/timnas-score-app',
        linkProject : '',
        id: 5,
        imagePath : '/livescore.jpg'
    },
    {
        title: 'Mail App with Encryption and Digital Signature',
        description: "Add cipher block encryption and digital signature to an open source email application. The application is able to send and receive encrypted email and verify the digital signature of the email.",
        techStacks : ['Kotlin'],
        linkGithub : 'https://github.com/primahafiz/mail-crypto',
        linkProject : '',
        id: 6,
        imagePath : '/mail.jpg'
    },
    {
        title: 'Covid Predicition Website',
        description: "Covid Prediction Website is a website that provides machine learning based covid prediction by symptoms.",
        techStacks : ['Django','Sklearn'],
        linkGithub : 'https://github.com/primahafiz/web-milestone-2',
        linkProject : '',
        id: 7,
        imagePath : '/covid.jpg'
    },
    {
        title: 'DNA Test Website with KMP Algorithm',
        description: "DNA Test Website is a website for testing if disease's DNA is included in user's DNA with string matching algorithm.",
        techStacks : ['ExpressJS','VueJS'],
        linkGithub : 'https://github.com/primahafiz/Tubes3_13520022',
        linkProject : '',
        id: 8,
        imagePath : '/dna.png'
    },
    {
        title: 'Message Queue Simulation',
        description: "Simulate message exchange between consumer and publisher using RabbitMQ message queue.",
        techStacks : ['Go','RabbitMQ','Docker'],
        linkGithub : 'https://github.com/primahafiz/rabbitmq',
        linkProject : '',
        id: 9,
        imagePath : '/rabbitmq.png'
    },
    {
        title: 'Minecraft Card Game Simulation',
        description: "Card game based on mincecraft character and using interactive GUI as the game interface.",
        techStacks : ['Java','Javax'],
        linkGithub : 'https://github.com/primahafiz/tubes2-oop',
        linkProject : '',
        id: 10,
        imagePath : '/minecraft.jpg'
    },
    {
        title: 'Python Code Parser with CFG',
        description: "Python code parser for syntax error checker using CYK algorithm and CFG. The parser is able to check if the python code is valid or not.",
        techStacks : ['Python'],
        linkGithub : 'https://github.com/primahafiz/Tubes-TBFO',
        linkProject : '',
        id: 11,
        imagePath : '/python.png'
    },
    {
        title: 'Image Compressor',
        description: "Program to compress image into smaller size using SVD decompostion and QR algorithm to improve the SVD program speed.",
        techStacks : ['Python','Django'],
        linkGithub : 'https://github.com/primahafiz/ImageCompressor',
        linkProject : '',
        id: 12,
        imagePath : '/qralgo.png'
    }
]
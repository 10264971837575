import { List } from "antd";
import { useState } from "react";
import ReactVisibilitySensor from "react-visibility-sensor";
import { Experience } from "../data/ExperienceData";


const ListExperiences = ({data} : {data:Experience[]}) => {
    const [isVisible, setIsVisible] = useState(data.map((elmt : Experience,idx : number) => false));
    return (
        <div>
            {data.map((item) => (
                <ReactVisibilitySensor
                    partialVisibility={true}
                    key={item.id}
                    onChange={(isInViewport : boolean) => {
                        isVisible[item.id-1] = isInViewport;
                        setIsVisible([...isVisible]);
                  }}>
                    <List bordered={false} style={{ marginTop:'8px',marginBottom:'8px', padding:'10px',borderRadius:'5px',backgroundColor:'#fbf3d5', color:'rgba(0,0,0,1)' ,opacity: isVisible[item.id-1] ? 1 : 0.25,transition: 'opacity 500ms linear'}}>
                        <List.Item style={{ opacity: isVisible[item.id-1] ? 1 : 0.25,transition: 'opacity 500ms linear' }}>
                            <List.Item.Meta
                                avatar={<img src={item.imagePath} style={{width: '120px', height: '120px'}} alt="experience-img"/>}
                                title={<b>{item.title}<br/>{item.company}</b>}
                                description={
                                    <div>
                                        <p style={{ color:'rgba(0,0,0,1)' }}>{item.startDate} - {item.endDate}<br/>{item.location}<br/>Description:<br/>
                                        </p>
                                        <ul style={{ color:'rgba(0,0,0,1)' }}>
                                            {item.description.map((desc,idx) => (
                                                <li key={idx}>{desc}</li>
                                            ))}
                                            </ul>
                                    </div>
                                }
                            />
                        </List.Item>
                    </List>
                </ReactVisibilitySensor>
            
            ))}
           
        </div>
    );
}

export default ListExperiences;
export interface Experience {
    title: string;
    description: string[];
    startDate: string;
    endDate: string;
    location: string;
    company: string;
    id: number;
    imagePath : string;
}


export const  experienceData : Experience[] = [
    {
        title: 'Research Intern',
        description: ['Conducting research in Japan focused on the topic of Anomalous Sound Detection, utilizing techniques from both sound processing and machine learning','Achieved 10,5% performance improvement compared to previous research'],
        startDate: 'Jan 2024',
        endDate: 'Mar 2024',
        location: 'Ishikawa, Japan',
        company: 'Japan Advanced Institute of Science and Technology (JAIST)',
        id: 1,
        imagePath : '/jaist.png'
    },
    {
        title: 'Software Engineer Intern',
        description: ['Implementing RESTful API for managing company’s organizational structure represented as a tree chart','Optimizing API interaction with postgres database result to 150% performance improvement','Tech stacks used : Gin Golang, GORM, Docker, Redis, React JS, PostgreSQL, MinIO'],
        startDate: 'May 2023',
        endDate: 'Aug 2023',
        location: 'Tangerang Selatan, Indonesia',
        company: 'OCBC NISP',
        id: 2,
        imagePath : '/ocbc.png'
    },
    {
        title: 'Software Engineer Part Time',
        description: ['Worked as a Full Stack Engineer to develop Enterprise Resource Planning (ERP) website','Tech stacks used : ASP.NET Core MVC, C#, MySQL, Javascript'],
        startDate: 'Feb 2023',
        endDate: 'Apr 2023',
        location: 'Remote',
        company: 'Pragma Informatika',
        id: 3,
        imagePath : '/pragma.jpeg'
    },
    {
        title: 'Practicum Assistant',
        description: ['Assisted various programming courses including Basic Programming, Algorithm and Data Structures, and Object Oriented Programming'],
        startDate: 'Jan 2023',
        endDate: 'Now',
        location: 'Bandung, Indonesia',
        company: 'Programming Laboratory',
        id: 4,
        imagePath : '/itb.png'
    },
]